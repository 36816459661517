// Angular
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { FormatUtils } from "@Core/Lib/Utils/format-utils";

// 3rd-party
import { FileUploadModule } from 'ng2-file-upload';
import { NgxMaskDirective, NgxMaskPipe, NgxMaskService, provideNgxMask } from 'ngx-mask';
import { QuillModule } from 'ngx-quill';

// Bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// Kendo
import { DropDownListModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { PopupModule } from '@progress/kendo-angular-popup';

// Qlik
import { QlikChartComponent } from '@Shared/Components/charts/qlik-chart/qlik-chart.component';
// Pipes
import { FileSizePipe } from '@Shared/Pipes/file-size.pipe';
import { ControlNameSpacerPipe } from '@Shared/Pipes/control-name-spacer.pipe';
import { SmCurrencyRegionPipe } from '@Shared/Pipes/sm-currency-region.pipe';
import { DomainlessIdPipe } from '@Shared/Pipes/domainless-id.pipe';
import { TextToControlIdPipe } from '@Shared/Pipes/text-to-control-id.pipe';
import { GuidReplacerPipe } from '@Work/Pipes/guid-replacer.pipe';
import { FeatureParentPipe, IsPermissionPipe } from '@Shared/Components/sm-feature-facets/feature-facet.pipe';
import { TimeInStrPipe } from '@Shared/Pipes/time.pipe';
import { SmDatePipe } from '@Shared/Pipes/sm-date.pipe';
import { SmNomenclaturePipe } from './Pipes/sm-nomenclature.pipe';
import { SmNumberPipe } from './Pipes/sm-number.pipe';

// Components
import { AccountRoleAssignmentBadge } from '@Work/Shared/account-role-assignment-badge/account-role-assignment-badge.component';
import { SmAccordionMenuComponent } from '@Shared/Components/sm-accordion-menu/sm-accordion-menu.component';
import { SmFileUploadComponent } from '@Shared/Components/sm-file-upload/sm-file-upload.component';
import { SmFilterFieldComponent } from '@Shared/Components/sm-filter-field/sm-filter-field.component';
import { SmHorizontalMenuComponent } from '@Shared/Components/sm-horizontal-menu/sm-horizontal-menu.component';
import { SmSelectionModal } from "@Shared/Components/sm-selection-modal/sm-selection-modal.component";
import { SmSimpleDialog } from '@Shared/Components/sm-simple-dialog/sm-simple-dialog.component';
import { SmSyncAPIDialog } from '@Shared/Components/sm-synchronous-api-dialog/sm-synchronous-api-dialog.component';
import { SmGlobalAlertsComponent } from '@Shared/Components/sm-global-alerts/sm-global-alerts.component';
import { SmGlobalNotificationsComponent } from '@Shared/Components/sm-global-notifications/sm-global-notifications.component';
import { SmGlobalNotificationsCardComponent } from '@Shared/Components/sm-global-notifications/sm-global-notifications-card/sm-global-notifications-card.component';
import { SmExtraActionsComponent } from '@Shared/Components/sm-extra-actions/sm-extra-actions.component';
import { SmHelpTooltipComponent } from '@Shared/Components/sm-help-tooltip/sm-help-tooltip.component';
import { SmWarningMessageComponent } from '@Shared/Components/sm-warning-message/sm-warning-message.component';
import { SmTagsComponent } from '@Shared/Components/sm-tags/sm-tags.component';
import { SmMainMenuComponent } from '@Shared/Components/sm-main-menu/sm-main-menu.component';
import { SmToastListComponent } from '@Shared/Components/sm-toast-list/sm-toast-list.component';
import { SmUserInfoComponent } from '@Shared/Components/sm-user-info/sm-user-info.component';
import { SmNotificationSettingsComponent } from '@Shared/Components/sm-notification-settings/sm-notification-settings.component';
import { SmAccountRoleBadgeComponent } from '@Shared/Components/sm-account-role-badge/sm-account-role-badge.component';
import { SmWorkGroupBadgeComponent } from '@Shared/Components/sm-work-group-badge/sm-work-group-badge.component';
import { SmCommentBadgeComponent } from '@Shared/Components/sm-comment-badge/sm-comment-badge.component';
import { SmSearchComponent } from '@Shared/Components/sm-search/sm-search.component';
import { SmDateByComponent } from '@Shared/Components/sm-date-by/sm-date-by.component';
import { SmNumberRadioSelectComponent } from '@Shared/Components/sm-number-radio-select/sm-number-radio-select.component';
import { SmAssignableBadgeComponent } from '@Shared/Components/sm-assignable-badge/sm-assignable-badge.component';
import { SmAuthorityLevelBadge } from '@Shared/Components/sm-authority-level-badge/sm-authority-level-badge.component';
import { SmAssignmentPicker } from '@Shared/Components/sm-assignable-picker/sm-assignment-picker.component';
import { SmDeleteConfDialog } from '@Shared/Components/sm-delete-conf-dialog/sm-delete-conf-dialog.component';
import { SmQuickSearchCard } from '@Shared/Components/sm-quick-search-card/sm-quick-search-card.component';
import { SmNavigationTitleBarComponent } from '@Shared/Components/sm-navigation-title-bar/sm-navigation-title-bar.component';
import { SmDonutComponent } from '@Shared/Components/sm-donut/sm-donut.component';
import { SmEditableTextComponent } from '@Shared/Components/sm-editable-text/sm-editable-text.component';
import { SmCopyClipboardComponent } from '@Shared/Components/sm-copy-clipboard/sm-copy-clipboard.component';
import { SmInfoStackComponent } from '@Shared/Components/sm-info-stack/sm-info-stack.component';
import { SmFeatureFacetsComponent } from '@Shared/Components/sm-feature-facets/sm-feature-facets.component';
import { SmSyncStatusComponent } from '@Shared/Components/sm-sync-status/sm-sync-status.component';
import { SmTimeSinceComponent } from '@Shared/Components/sm-time-since/sm-time-since.component';
import { SmFormComponent } from '@Shared/Components/sm-form/sm-form.component';
import { SmFormFieldBooleanComponent } from '@Shared/Components/sm-form/fields/sm-form-field-boolean.component';
import { SmFormFieldComponent } from '@Shared/Components/sm-form/sm-form-field.component';
import { SmFormFieldDateComponent } from '@Shared/Components/sm-form/fields/sm-form-field-date.component';
import { SmFormFieldDecimalComponent } from '@Shared/Components/sm-form/fields/sm-form-field-decimal.component';
import { SmFormFieldEmailComponent } from '@Shared/Components/sm-form/fields/sm-form-field-email.component';
import { SmFormFieldIntegerComponent } from '@Shared/Components/sm-form/fields/sm-form-field-integer.component';
import { SmFormFieldLabelComponent } from '@Shared/Components/sm-form/fields/label/sm-form-field-label.component';
import { SmFormFieldMoneyComponent } from '@Shared/Components/sm-form/fields/sm-form-field-money.component';
import { SmFormFieldMultiSelectComponent } from '@Shared/Components/sm-form/fields/sm-form-field-multi-select.component';
import { SmFormFieldPhoneComponent } from '@Shared/Components/sm-form/fields/sm-form-field-phone.component';
import { SmFormFieldRadioOptionsComponent } from '@Shared/Components/sm-form/fields/sm-form-field-radio-options.component';
import { SmFormFieldSelectComponent } from '@Shared/Components/sm-form/fields/sm-form-field-select.component';
import { SmFormFieldStringComponent } from '@Shared/Components/sm-form/fields/sm-form-field-string.component';
import { SmFormFieldTimestampComponent } from '@Shared/Components/sm-form/fields/sm-form-field-timestamp.component';
import { SmFormFieldUrlComponent } from '@Shared/Components/sm-form/fields/sm-form-field-url.component';
import { SmFormFieldYearComponent } from '@Shared/Components/sm-form/fields/sm-form-field-year.component';
import { SmFormFieldZipComponent } from '@Shared/Components/sm-form/fields/sm-form-field-zip.component';
import { SmFormPreviousValueComponent } from '@Shared/Components/sm-form/fields/previous-value/sm-form-previous-value.component';
import { SmFormSectionComponent } from '@Shared/Components/sm-form/sm-form-section.component';
import { SmFormSectionInstanceComponent } from '@Shared/Components/sm-form/sm-form-section-instance.component';
import { SmFormSectionModalComponent } from '@Shared/Components/sm-form/sm-form-section-modal.component';
import { SmFormStaticElementComponent } from '@Shared/Components/sm-form/sm-form-static-element.component';
import { SmFormRowComponent } from '@Shared/Components/sm-form/rows/sm-form-row.component';
import { SmFormCurrencyBlockComponent } from '@Shared/Components/sm-form/rows/sm-form-currency-block.component';
import { SmSelectAreaModalComponent } from '@Shared/Components/sm-select-area/sm-select-area-modal.component';
import { SmSelectAreaComponent } from '@Shared/Components/sm-select-area/sm-select-area.component';
import { SmSelectTenantComponent } from '@Shared/Components/sm-select-tenant/sm-select-tenant.component';
import { SmMultiOptionsButtonComponent } from '@Shared/Components/sm-multi-options-button/sm-multi-options-button.component';
import { SmRatingComponent } from '@Work/Accounts/Rating/sm-rating.component';
import { SmCollapsibleComponent } from '@Work/Shared/sm-collapsible/sm-collapsible.component';
import { SmInputNumberComponent } from './Components/inputs/sm-input-number/sm-input-number.component';
import { SmWorkflowBadgeComponent } from './Components/sm-workflow-badge/sm-workflow-badge.component';
import { SmProducerSearchComponent } from './Components/sm-producer-search/sm-producer-search.component';
import { SmIntegrationBadgeComponent } from './Components/sm-integration-badge/sm-integration-badge.component';

import { UnityAvatarComponent } from '@Shared/Components/unity-avatar/unity-avatar.component';
import { UnityBadgeComponent } from '@Shared/Components/unity-badge/unity-badge.component';
import { UnityBannerComponent } from './Components/unity-banner/unity-banner.component';
import { UnityButtonComponent } from '@Shared/Components/unity-button/unity-button.component';
import { UnityCardBasicComponent } from './Components/unity-card/unity-card-basic/unity-card-basic.component';
import { UnityCardMetricComponent } from './Components/unity-card/unity-card-metric/unity-card-metric.component';
import { UnityUserMenuComponent } from '@Shared/Components/unity-user-menu/unity-user-menu.component';
import { UnityHeaderComponent } from '@Shared/Components/unity-header/unity-header.component';
import { UnityToggleComponent } from '@Shared/Components/unity-toggle/unity-toggle.component';
import { UnityModalComponent } from '@Shared/Components/unity-modal/unity-modal.component';
import { UnityToastComponent } from '@Shared/Components/unity-toasts/unity-toast.component';
import { UnityToastsComponent } from '@Shared/Components/unity-toasts/unity-toasts.component';
import { UnityNavComponent } from '@Shared/Components/unity-nav/unity-nav.component';
import { UnityFormControlTextSingleComponent } from '@Shared/Components/unity-form/unity-control-text-single/unity-control-text-single.component';
import { UnityFormControlTextMultiComponent } from '@Shared/Components/unity-form/unity-control-text-multi/unity-control-text-multi.component';
import { UnityFormControlEmailComponent } from '@Shared/Components/unity-form/unity-control-email/unity-control-email.component';
import { UnityFormControlPasswordComponent } from '@Shared/Components/unity-form/unity-control-password/unity-control-password.component';
import { UnityFormControlDateComponent } from '@Shared/Components/unity-form/unity-control-date/unity-control-date.component';
import { UnityFormControlPhoneComponent } from '@Shared/Components/unity-form/unity-control-phone/unity-control-phone.component';
import { UnityFormControlDropdownComponent } from '@Shared/Components/unity-form/unity-control-dropdown/unity-control-dropdown.component';
import { UnityFormControlComboboxComponent } from '@Shared/Components/unity-form/unity-control-combobox/unity-control-combobox.component';
import { UnityFormControlCheckboxComponent } from '@Shared/Components/unity-form/unity-control-checkbox/unity-control-checkbox.component';
import { UnityFormControlCheckboxGroupComponent } from '@Shared/Components/unity-form/unity-control-checkbox-group/unity-control-checkbox-group.component';
import { UnityFormControlRadioComponent } from '@Shared/Components/unity-form/unity-control-radio/unity-control-radio.component';
import { UnityFormControlToggleSwitchComponent } from '@Shared/Components/unity-form/unity-control-toggle-switch/unity-control-toggle-switch.component';
import { UnityFormControlDualListboxComponent } from '@Shared/Components/unity-form/unity-control-dual-listbox/unity-control-dual-listbox.component';
import { UnityFormControlFileUploadComponent } from '@Shared/Components/unity-form/unity-control-file-upload/unity-control-file-upload.component';
import { UnityFormControlDropZoneComponent } from '@Shared/Components/unity-form/unity-control-drop-zone/unity-control-drop-zone.component';
import { UnityMenuContextualComponent } from '@Shared/Components/unity-menu/unity-menu-contextual.component';
import { UnitySideNavigationComponent } from './Components/unity-side-navigation/unity-side-navigation.component';
import { UnityListItemComponent } from './Components/unity-list/unity-list-item.component';
import { UnityIconComponent } from './Components/unity-icon/unity-icon.component';


// Directives
import { UniqueValueDirective } from '@Shared/Directives/unique-value.directive';
import { ClickCursorDirective } from '@Shared/Directives/click-cursor.directive';
import { NgbTooltipStickyDirective } from '@Shared/Directives/ngbtooltip-sticky.directive';
import { HasPermissionDirective, HasAnyPermissionDirective, HasAllPermissionDirective, HasNotPermissionDirective, HasNotAllPermissionDirective, HasNotAnyPermissionDirective } from '@Shared/Directives/sm-permissions.directive';
import { TenantHasFacetDirective, TenantHasNotFacetDirective } from '@Shared/Directives/sm-tenant-facets.directive';

// Services
import { SmFormService } from '@Shared/Components/sm-form/sm-form-service';
import { SmInputZipcodeComponent } from './Components/inputs/sm-input-zipcode/sm-input-zipcode.component';
import { SmInputMoneyComponent } from './Components/inputs/sm-input-money/sm-input-money.component';
import { SmNotFoundComponent } from './Components/sm-not-found/sm-not-found.component';
import { SmClientErrorComponent } from './Components/sm-client-error/sm-client-error.component';
import { GaugeChartComponent } from './Components/charts/gauge-chart/gauge-chart.component';
import { ArcGaugeModule, GaugesModule } from '@progress/kendo-angular-gauges';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { UserTaskTimerComponent } from '@Work/Shared/user-task-timer/user-task-timer.component';
import { UnityStepperComponent } from './Components/unity-stepper/unity-stepper.component';
import { UnityLoadingIndicatorComponent } from './Components/unity-loading-indicator/unity-loading-indicator.component';
import { UnityListComponent } from './Components/unity-list/unity-list.component';
import { TaskTimerComponent } from '@Work/Shared/task-timer/task-timer.component';
import { SmExpandableTextComponent } from './Components/sm-expandable-text/sm-expandable-text.component';
import { UnityChipComponent } from './Components/unity-chip/unity-chip.component';
import { SmPolicyTableComponent } from './Components/sm-policy-table/sm-policy-table.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        HttpClientModule,
        ClipboardModule,
        FileUploadModule,
        DropDownsModule,
        PopupModule,
        GridModule,
        InputsModule,
        ButtonsModule,
        DropDownListModule,
        NgbModule,
        NgxMaskDirective,
        NgxMaskPipe,
        GaugesModule,
        ChartsModule,
        ArcGaugeModule,
        QuillModule
    ],
    declarations: [
        AccountRoleAssignmentBadge,
        SmIntegrationBadgeComponent,
        SmGlobalAlertsComponent,
        SmGlobalNotificationsComponent,
        SmGlobalNotificationsCardComponent,
        SmToastListComponent,
        SmMainMenuComponent,
        SmQuickSearchCard,
        SmUserInfoComponent,
        SmNotificationSettingsComponent,
        ControlNameSpacerPipe,
        SmCurrencyRegionPipe,
        FileSizePipe,
        GuidReplacerPipe,
        DomainlessIdPipe,
        TextToControlIdPipe,
        TimeInStrPipe,
        SmDatePipe,
        SmNomenclaturePipe,
        SmNumberPipe,

        SmRatingComponent,
        SmCollapsibleComponent,
        SmMultiOptionsButtonComponent,
        SmAccordionMenuComponent,
        SmFileUploadComponent,
        SmFilterFieldComponent,
        SmHorizontalMenuComponent,
        SmTagsComponent,
        SmSimpleDialog,
        SmSyncAPIDialog,
        SmSyncStatusComponent,
        SmSelectionModal,
        UniqueValueDirective,
        ClickCursorDirective,
        NgbTooltipStickyDirective,
        HasPermissionDirective,
        HasAnyPermissionDirective,
        HasAllPermissionDirective,
        HasNotPermissionDirective,
        HasNotAnyPermissionDirective,
        HasNotAllPermissionDirective,
        TenantHasFacetDirective,
        TenantHasNotFacetDirective,
        SmExtraActionsComponent,
        SmHelpTooltipComponent,
        SmWarningMessageComponent,
        SmAssignableBadgeComponent,
        SmAccountRoleBadgeComponent,
        SmWorkGroupBadgeComponent,
        SmAuthorityLevelBadge,
        SmCommentBadgeComponent,
        SmSearchComponent,
        SmDateByComponent,
        SmNumberRadioSelectComponent,
        SmAssignmentPicker,
        SmDeleteConfDialog,
        SmNavigationTitleBarComponent,
        SmDonutComponent,
        SmEditableTextComponent,
        SmCopyClipboardComponent,
        SmInfoStackComponent,
        SmFeatureFacetsComponent,
        FeatureParentPipe,
        IsPermissionPipe,
        SmTimeSinceComponent,
        SmSelectAreaComponent,
        SmSelectAreaModalComponent,
        SmSelectTenantComponent,
        SmWorkflowBadgeComponent,
        SmClientErrorComponent,
        SmNotFoundComponent,
        SmProducerSearchComponent,
        UserTaskTimerComponent,
        TaskTimerComponent,
        SmPolicyTableComponent,       

        SmFormComponent,
        SmFormSectionComponent,
        SmFormSectionInstanceComponent,
        SmFormSectionModalComponent,
        SmFormFieldComponent,
        SmFormFieldLabelComponent,
        SmFormPreviousValueComponent,
        SmFormFieldStringComponent,
        SmFormFieldMultiSelectComponent,
        SmFormFieldEmailComponent,
        SmFormFieldPhoneComponent,
        SmFormFieldYearComponent,
        SmFormFieldZipComponent,
        SmFormFieldIntegerComponent,
        SmFormFieldDecimalComponent,
        SmFormFieldDateComponent,
        SmFormFieldMoneyComponent,
        SmFormFieldUrlComponent,
        SmFormFieldTimestampComponent,
        SmFormFieldBooleanComponent,
        SmFormFieldRadioOptionsComponent,
        SmFormFieldSelectComponent,
        SmFormStaticElementComponent,
        SmFormRowComponent,
        SmFormCurrencyBlockComponent,
        SmExpandableTextComponent,

        SmInputNumberComponent,
        SmInputMoneyComponent,
        SmInputZipcodeComponent,

        UnityAvatarComponent,
        UnityBadgeComponent,
        UnityBannerComponent,
        UnityButtonComponent,
        UnityCardBasicComponent,
        UnityCardMetricComponent,
        UnityChipComponent,
        UnityUserMenuComponent,
        UnityHeaderComponent,
        UnityToggleComponent,
        UnityToastComponent,
        UnityToastsComponent,
        UnityModalComponent,
        UnityNavComponent,
        UnityFormControlTextSingleComponent,
        UnityFormControlTextMultiComponent,
        UnityFormControlEmailComponent,
        UnityFormControlPasswordComponent,
        UnityFormControlDateComponent,
        UnityFormControlPhoneComponent,
        UnityFormControlDropdownComponent,
        UnityFormControlComboboxComponent,
        UnityFormControlCheckboxComponent,
        UnityFormControlCheckboxGroupComponent,
        UnityFormControlRadioComponent,
        UnityFormControlToggleSwitchComponent,
        UnityFormControlDualListboxComponent,
        UnityFormControlFileUploadComponent,
        UnityFormControlDropZoneComponent,
        UnityMenuContextualComponent,
        UnityStepperComponent,
        UnityLoadingIndicatorComponent,
        UnityListComponent,
        UnitySideNavigationComponent,
        UnityListItemComponent,
        UnityIconComponent,

        GaugeChartComponent,
        QlikChartComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        HttpClientModule,
        ClipboardModule,
        FileUploadModule,
        GridModule,
        DropDownsModule,
        PopupModule,
        InputsModule,
        ButtonsModule,
        DropDownListModule,
        NgbModule,
        NgxMaskDirective,
        NgxMaskPipe,
        QuillModule,

        AccountRoleAssignmentBadge,
        SmTagsComponent,
        SmGlobalAlertsComponent,
        SmGlobalNotificationsComponent,
        SmGlobalNotificationsCardComponent,
        SmToastListComponent,
        SmMainMenuComponent,
        SmUserInfoComponent,
        SmNotificationSettingsComponent,
        SmQuickSearchCard,
        ControlNameSpacerPipe,
        SmCurrencyRegionPipe,
        FileSizePipe,
        GuidReplacerPipe,
        DomainlessIdPipe,
        TextToControlIdPipe,
        TimeInStrPipe,
        SmDatePipe,
        SmNomenclaturePipe,
        SmNumberPipe,

        SmRatingComponent,
        SmCollapsibleComponent,
        SmMultiOptionsButtonComponent,
        SmAccordionMenuComponent,
        SmFileUploadComponent,
        SmFilterFieldComponent,
        SmHorizontalMenuComponent,
        SmSimpleDialog,
        SmSyncAPIDialog,
        SmSelectionModal,
        UniqueValueDirective,
        ClickCursorDirective,
        NgbTooltipStickyDirective,
        HasPermissionDirective,
        HasAnyPermissionDirective,
        HasAllPermissionDirective,
        HasNotPermissionDirective,
        HasNotAnyPermissionDirective,
        HasNotAllPermissionDirective,
        TenantHasFacetDirective,
        TenantHasNotFacetDirective,
        SmExtraActionsComponent,
        SmHelpTooltipComponent,
        SmWarningMessageComponent,
        SmAssignableBadgeComponent,
        SmAccountRoleBadgeComponent,
        SmWorkGroupBadgeComponent,
        SmAuthorityLevelBadge,
        SmCommentBadgeComponent,
        SmSearchComponent,
        SmDateByComponent,
        SmNumberRadioSelectComponent,
        SmAssignmentPicker,
        SmDeleteConfDialog,
        SmNavigationTitleBarComponent,
        SmDonutComponent,
        SmEditableTextComponent,
        SmCopyClipboardComponent,
        SmInfoStackComponent,
        SmFeatureFacetsComponent,
        SmTimeSinceComponent,
        SmSelectAreaComponent,
        SmSelectAreaModalComponent,
        SmSelectTenantComponent,
        SmWorkflowBadgeComponent,
        SmClientErrorComponent,
        SmNotFoundComponent,
        SmProducerSearchComponent,
        UserTaskTimerComponent,
        TaskTimerComponent,
        SmPolicyTableComponent,   

        SmFormComponent,
        SmFormSectionComponent,
        SmFormSectionInstanceComponent,
        SmFormSectionModalComponent,
        SmFormFieldComponent,
        SmFormFieldLabelComponent,
        SmFormPreviousValueComponent,
        SmFormFieldStringComponent,
        SmFormFieldMultiSelectComponent,
        SmFormFieldEmailComponent,
        SmFormFieldPhoneComponent,
        SmFormFieldYearComponent,
        SmFormFieldZipComponent,
        SmFormFieldIntegerComponent,
        SmFormFieldDecimalComponent,
        SmFormFieldDateComponent,
        SmFormFieldMoneyComponent,
        SmFormFieldUrlComponent,
        SmFormFieldTimestampComponent,
        SmFormFieldBooleanComponent,
        SmFormFieldRadioOptionsComponent,
        SmFormFieldSelectComponent,
        SmFormStaticElementComponent,
        SmFormRowComponent,
        SmFormCurrencyBlockComponent,
        SmExpandableTextComponent,

        SmInputNumberComponent,
        SmInputMoneyComponent,
        SmInputZipcodeComponent,

        UnityAvatarComponent,
        UnityBadgeComponent,
        UnityBannerComponent,
        UnityButtonComponent,
        UnityCardBasicComponent,
        UnityCardMetricComponent,
        UnityChipComponent,
        UnityUserMenuComponent,
        UnityHeaderComponent,
        UnityToggleComponent,
        UnityToastComponent,
        UnityToastsComponent,
        UnityModalComponent,
        UnityNavComponent,
        UnityFormControlTextSingleComponent,
        UnityFormControlTextMultiComponent,
        UnityFormControlEmailComponent,
        UnityFormControlPasswordComponent,
        UnityFormControlDateComponent,
        UnityFormControlPhoneComponent,
        UnityFormControlDropdownComponent,
        UnityFormControlComboboxComponent,
        UnityFormControlCheckboxComponent,
        UnityFormControlCheckboxGroupComponent,
        UnityFormControlRadioComponent,
        UnityFormControlToggleSwitchComponent,
        UnityFormControlDualListboxComponent,
        UnityFormControlFileUploadComponent,
        UnityFormControlDropZoneComponent,
        UnityMenuContextualComponent,
        UnityStepperComponent,
        UnityLoadingIndicatorComponent,
        UnityListComponent,
        UnitySideNavigationComponent,
        UnityListItemComponent,
        UnityIconComponent,

        GaugeChartComponent,
        QlikChartComponent
    ],
    providers: [
        SmFormService,
        NgxMaskService,
        provideNgxMask({
            allowNegativeNumbers: true,
            thousandSeparator: FormatUtils.getThousandSeparator(),
            decimalMarker: FormatUtils.getDecimalMarker() as "." | ","
        })
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
        }
    }
}
