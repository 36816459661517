import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import * as _ from 'lodash';
import { Observable, of, forkJoin } from 'rxjs';
import { share } from 'rxjs/operators';

import { GlobalMessages } from '@Services/global-messages';
import { BaseService } from '@Services/base-service';
import { LoggingService } from '@Services/logging-service';
import { LoadingService } from '@Services/loading-service';

import { TenantContext } from '@Core/Lib/Contexts/tenant-context';
import { Document, Correspondence } from '@Core/CodeGen/Models/area.models';
import { HttpHeaderNames } from '@Core/Lib/Enums/http-header-names';
import { AccountContext } from '@Core/Lib/Contexts/account-context';
import { IServiceResponse } from '@Core/Lib/model';


@Injectable()
export class DocumentService
    extends BaseService {

    private serviceUrls = class ServiceUrls {

        private static baseUrl: string = BaseService.baseUrl + '/';
        public static documents: string = ServiceUrls.baseUrl + 'Documents';

        public static documentsByAccount(accountId: string): string {
            return `${ServiceUrls.documents}/${accountId}`
        }

        public static document(accountId: string, documentId: string): string {
            return `${ServiceUrls.documents}/${accountId}/${documentId}`
        }

        public static documentUrl(accountId: string, documentId: string): string {
            return `${ServiceUrls.documents}/${accountId}/${documentId}/Url`
        }

        public static anyCorrespondence: string = ServiceUrls.documents + '/Correspondence';

        public static correspondence(accountId: string, correspondenceId: string): string {
            return `${ServiceUrls.documents}/${accountId}/Correspondence/${correspondenceId}`;
        }
    }

    public docCategoriesLoaded: boolean;

    constructor(private http: HttpClient,
        protected globalMessages: GlobalMessages,
        private tenantContext: TenantContext,
        protected loggingService: LoggingService,
        protected loadingService: LoadingService
    ) {
        super(globalMessages, loggingService, loadingService);
    }

    public loadDocuments(accountId: string, context?: AccountContext) {
        let cntxt = context ? context : this.tenantContext;
        const url = this.serviceUrls.documentsByAccount(accountId);
        const request = this.http.get<any>(url);
        this.handleNormalGetRequest(Document, request, cntxt);
    }

    public uploadDocuments(documentsToUpload: DocumentAndFile[], replace: boolean): Observable<any>{
        var uploadRequests: Observable<any>[] = [];
        documentsToUpload.forEach(document =>{
            uploadRequests.push(this.uploadDocument(document.Document, replace, document.File, document.SendForExtraction));
        });
        return forkJoin<any>(uploadRequests);
    }

    public uploadDocument(document: Document, replace: boolean, file: File, sendForExtraction: boolean): Observable<any> {
        const url = this.serviceUrls.documentsByAccount(document.AccountId) + '/Upload';
        const params = new HttpParams().set("replace", replace.toString());

        const request = this.http.post<any>(url, document.serialize(), { params }).pipe(share());
        request.subscribe({
            next: response => {
                var document = new Document().deserialize(response.Content, this.tenantContext);
                var uploadUrl = this.convertDocumentUploadUrl(document.data["UploadUrl"]) + "&useAsyncAPI=true";

                let params = new HttpParams().set("sendForExtraction", sendForExtraction);
                
                const uploadRequest = this.http.post<any>(uploadUrl, file, {
                    headers: new HttpHeaders().set(HttpHeaderNames.ContentType, "octet-stream"),
                    params: params
                }).pipe(share());
                uploadRequest.subscribe({
                    next: response => { 
                        new Document().deserialize(response.Content, this.tenantContext);
                    },
                    error: error => {
                        this.handleError(error)
                    }
                });
            },
            error: error => {
                this.handleError(error)
            }
        });
        return request;
    }

    // Converts the upload url to one that our API will accept coming from the application.
    // This is needed because our backend passes this url to us, but it is in the form of someone directly accessing the API.
    public convertDocumentUploadUrl(uploadUrl: string): string {
        var positionOfDocuments = uploadUrl.indexOf("Documents");
        return this.serviceUrls.documents + uploadUrl.substring(positionOfDocuments + "Documents".length);
    }

    public updateDocument(document: Document, accountContext: AccountContext) {
        const url = this.serviceUrls.document(document.AccountId, document.Id);
        const self = this;
        const request = this.http.put<any>(url, document.serialize()).pipe(share());
        this.handleNormalPostPutRequest(Document, request, accountContext);
        return request;
    }

    public deleteDocument(document: Document, accountContext: AccountContext) {
        const url = this.serviceUrls.document(document.AccountId, document.Id);
        let request = this.http.delete<any>(url).pipe(share());
        //using post/put instead of delete, due to deferred delete
        this.handleNormalPostPutRequest(Document, request, accountContext);
        return request;
    }

    public saveCorrespondence(correspondence: Correspondence, context: AccountContext) {
        const url = this.serviceUrls.correspondence(context.accountId, correspondence.Id);
        const request = this.http.put<any>(url, correspondence.serialize());
        this.handleNormalPostPutRequest(Correspondence, request, context);
    }

    public getDocumentUrl(documentId: string, accountId: string, urlType: 'view' | 'download' | 'edit') {
        const url = this.serviceUrls.documentUrl(accountId, documentId);
        const params = new HttpParams().set("urlType", urlType);
        const request = this.http.get<any>(url, { params }).pipe(share());
        return request;
    }
}

export interface DocumentAndFile{
    Document: Document,
    File: File,
    SendForExtraction: boolean
}