/** Contains constants to track the Tenant Permissions */
export enum TenantPermissions
{
    /** View the level of system access for individuals or remote systems.  Due to the security nature of this module, selection of ANY permission in this module removes the ability to also select any permission in any other module. */
    SystemAccess = "T.Sec",
    /** Users are interactive logins into the UW Workbench user interface.  They are NOT used for automation or batch operations (see API Keys) as some APIs are not available to these users.  Each user is assigned one or more security roles that determine what they can functionally do within the UW Workbench */
    SystemAccess_Users = "T.Sec.User",
    /** Invite new users, disable current users, control access to areas, reset passwords and assign security roles. */
    SystemAccess_Users_ManageUsers = "T.Sec.User.Mng",
    /** Security roles are a group of permissions.  Each user and api key is assigned one or more security roles that determine what they are functionally allowed to do within the UW Workbench */
    SystemAccess_SecurityRoles = "T.Sec.Perm",
    /** Create new security roles or modify the permissions of existing security roles. */
    SystemAccess_SecurityRoles_ManageSecurityRoles = "T.Sec.Perm.Mng",
    /** API keys are system credentials that are used for automation or batch operations.  API Keys have access to ALL APIs available (unlike users). Both developer and application API keys are supported with each API Key being assigned an Area (e.g. Test or Production) and one or more security roles that can be used to limit what a specific application or developer can do through the API. */
    SystemAccess_ApiKeys = "T.Sec.Api",
    /** Create, enable and disable application and developer API Keys.  This also allows the retrieving the Client Secrets for ALL API keys in the system even if they don't belong to you. */
    SystemAccess_ApiKeys_ManageApiKeys = "T.Sec.Api.Mng",

    /** View and control system-wide settings and configurations shared by all areas */
    Tenant = "T.TCnf",
    /**  */
    Tenant_Workforce = "T.TCnf.Wfrc",
    /** Account roles are designated responsibilities for an account.  Each account role is filled by an eligible team member.  Account roles are added to program where team member eligibility and authority level can be configured (See Programs/Teams). */
    Tenant_Workforce_ManageAccountRoles = "T.TCnf.Wfrc.Ar",
    /** Work groups are system-wide sets of workers who share a common queue of work.  Workgroups are added to programs and then assigned to tasks. Tasks assigned to a workgroup are 'as if' they are assigned to everyone in the workgroup.  Members start and stop work on a workgroup where accounts are reserved for them based on a specified prioritization algorithm. */
    Tenant_Workforce_ManageWorkGroups = "T.TCnf.Wfrc.Wg",
    /** Configure the necessary organizational and functional data to properly process insurance policies */
    Tenant_Insurance = "T.TCnf.Ins",
    /** Companies are legal entities that offer an insurance product.  This permission allows you to create, modify and disable companies in the UW Workbench. */
    Tenant_Insurance_ManageCompanies = "T.TCnf.Ins.Comp",
    /** A Product in the UW Workbench is any policy type that is specifically presented to a market.  Products are associated with the carrier or legal entity covering the risk (Companies).  Products are added to programs to track policies.  This permission allows you to configure new products for use in your programs. */
    Tenant_Insurance_ManageProducts = "T.TCnf.Ins.Pdct",
    /** Producers sell and distribute insurance products.  Configure producers and set up their access to sell certain products as well as set up rules for determining default commissions for new business and renewals. */
    Tenant_Insurance_ManageProducers = "T.TCnf.Ins.Pdcr",
    /** UW Workbench comes with a comprehensive set of transactions that are supported but some implementations require custom transaction types.  Create new ones or modify the existing ones */
    Tenant_Insurance_ManageTransactionTypes = "T.TCnf.Ins.Trns",
    /** An area is an isolated space where Program Configurations are deployed and users can create real accounts.  All Tenant configurations are shared by all areas.  Examples are Test, Production, etc. */
    Tenant_Areas = "T.TCnf.Area",
    /** Create new temporary or permanent areas.  Allows you to disable, change the tier and put areas to sleep.  Warning: Creating areas may result in additional charges.  (not yet implemented) */
    Tenant_Areas_ManageAreas = "T.TCnf.Area.Mng",
    /** Deploy program configurations to areas.  Allows the undeploy of any program configuration that is NOT deployed to a 'Production' area. */
    Tenant_Areas_DeployPrograms = "T.TCnf.Area.Dply",
    /** Manage Custom Lists and other top level settings */
    Tenant_Settings = "T.TCnf.Set",
    /** Create, modify, enable and disable settings. Ex: Create Holidays or Modify Stop Reasons */
    Tenant_Settings_ManageSettings = "T.TCnf.Set.List",
    /** Documents are supporting evidence for underwriter consumption or has backup evidence of data used for rules and decision making.  Add, modify or remove categories for document management. */
    Tenant_Settings_ManageDocuments = "T.TCnf.Set.Doc",
    /** Enable or disable features for the entire product, some at an area level. */
    Tenant_Settings_ManageFeatures = "T.TCnf.Set.Feat",
    /** Create, modify, enable and disable service levels. */
    Tenant_Settings_ManageServiceLevels = "T.TCnf.Set.SL",
    /** Integrations are facilitated by outbound messages (webhooks) and inbound data (api).  Webhooks are built into workflows to notify remote systems are the proper time.  Due to security concerns, management of API Keys are granted in the Security Access module; but, this feature will allow users to see the API keys and their current status.  With this permission, only developer contacts can view the credentials of their own API keys. */
    Tenant_Integrations = "T.TCnf.Intg",
    /** Create, modify, enable and disable webhooks.  Regenerate webhook signing keys. */
    Tenant_Integrations_ManageWebhooks = "T.TCnf.Intg.Wbhk",
    /** The UW workbench delivers emails as part of user invitations or password resets.  They can also be delivered as part of a workflow.  */
    Tenant_Email = "T.TCnf.Email",
    /** Add and modify email templates to customize messaging. */
    Tenant_Email_ManageTemplates = "T.TCnf.Email.Tmpl",
    /** Enables the use of Insurity BI which provides analytics and out of the box reports for the tenant. */
    Tenant_Analytics = "T.TCnf.Alytc",
    /** Create, modify, enable and disable tenant dashboards. */
    Tenant_Analytics_ManageTenantDashboards = "T.TCnf.Alytc.MngDash",
    /** This feature enables a KPI and Visualization driven dashboard specific to the Tenant data in Sure Underwriting. The dashboard can utilize embedding reports from Insurity BI and improve the user experience with analytics. */
    Flag_Tenant_Analytics_Dashboards = "T.TCnf.Alytc.WrkDash",

    /** Create and modify Insurance Program configurations.  A program is a grouping of set of policy types that are all underwritten together. */
    Programs = "T.Prog",
    /** A program team is comprised of team members that are assigned to eligible account roles as well as workgroups.  Once account roles and workgroups are added to a team they can be configured as initial assignees to workflow tasks. */
    Programs_Teams = "T.Prog.Team",
    /** Add and Remove Team Members, add account roles and determine eligible members.  Add workgroups and determine authority levels.  You can also choose the program manager. */
    Programs_Teams_ManageTeams = "T.Prog.Team.Mng",
    /** Program configurations a packaged up as a Revision.  A revision is comprised of one or more 'changes'.  A new revision is automatically created when the previous revision is deployed to production.This feature lets a user view all the program configuration revisions. */
    Programs_Changes = "T.Prog.Chng",
    /** Start a new change set, modify existing changes sets, and add them to the next revision. This permission also allows the creation of new programs. */
    Programs_Changes_MakeChanges = "T.Prog.Chng.Make",
    /** Deleting a change will rollback all of the program modification including restoring deleted objects.  You can not delete or rollback any change in revision 0 since there is nothing to rollback to... */
    Programs_Changes_RollbackChanges = "T.Prog.Chng.Roll",
    /** Committing a change set declares that it is done.  All changes in a revision must be committed in order to deploy the revision.  This permission also allows the Uncommitting of changes. */
    Programs_Changes_CommitChanges = "T.Prog.Chng.Cmt",
    /** Enable Program Patching feature */
    Flag_Programs_Changes_Patching = "T.Prog.Chng.Patch",
    /** Enable Program Assets feature */
    Flag_Programs_Changes_Assets = "T.Prog.Chng.Assets",
    /** The DataStore is the structure that stores all data used when executing a workflow.  It can be configured to store whatever is required for the program.  Once a data store is defined, applications can be created to allow for data entry in workflow tasks. */
    Programs_Data = "T.Prog.Data",
    /** Create and modify data stores.  Add, edit and delete datagroups and datapoints including configuring calculated datapoints. */
    Programs_Data_ManageDataStores = "T.Prog.Data.DS",
    /** Applications are data entry screens provide awareness of their own completion.  Applications are comprised of sections, elements and fields.  Fields are configured to be optional, required, conditional, etc. */
    Programs_Data_ManageApplications = "T.Prog.Data.App",
    /** Workflows are the core of the underwriting process.  Workflows (if transactional) manage products and have rules to determine their flow and operation. */
    Programs_Workflows = "T.Prog.Wkfl",
    /** Add and Edit workflows including adding phases, tasks and dependencies.  Can also configure automation options for each task and configure sets of workflows to function together. */
    Programs_Workflows_ManageWorkflows = "T.Prog.Wkfl.Mng",
    /** Create rules that execute off of the datastore and modify workflows, risks, assignments, etc. */
    Programs_Workflows_ManageRules = "T.Prog.Wkfl.Rule",
    /** Add, remove and edit Insurance products processes by a given program. */
    Programs_Workflows_ManageProducts = "T.Prog.Wkfl.Pdct",

    /** Functionality for managers.  This includes team management, managing account role assignments for accounts, etc. */
    Manage = "T.Mng",
    /** Provides functionality to view individual teams, account roles and eligibility, workgroups and authority levels. */
    Manage_Teams = "T.Mng.Team",
    /** You must be designated the Program Manager for a progam AND have this permission in order to add and remove Team Members, add account roles, and determine eligible members */
    Manage_Teams_ManageTeams = "T.Mng.Team.Mng",
    /** Manage individual accounts in bulk and view the status and progress of account and policy activity. */
    Manage_Accounts = "T.Mng.Acct",
    /** Search for accounts based on various filters and bulk reassign any account role. */
    Manage_Accounts_BulkReassignAccounts = "T.Mng.Acct.Bulk",

    /** Features for working on or managing an account */
    Work = "T.Wrk",
    /** An account is a customer that is buying one or more insurance products.  List accounts, Retrieve account details and perform basic account operations. */
    Work_Accounts = "T.Wrk.Acct",
    /** Create a new account and start workflows on it. */
    Work_Accounts_AddAccounts = "T.Wrk.Acct.Add",
    /** Assign or reassign producers to accounts. */
    Work_Accounts_AssignProducers = "T.Wrk.Acct.Pdcr",
    /** Add and edit journal entries */
    Work_Accounts_JournalEntries = "T.Wrk.Acct.Jrnl",
    /** Search for accounts. */
    Work_Accounts_SearchAccounts = "T.Wrk.Acct.Srch",
    /** Enable option for users to input an account number when creating a new account. */
    Flag_Work_Accounts_NewAccountNumberOverride = "T.Wrk.Acct.NumOv",
    /** Enables the account prioritization feature. */
    Flag_Work_Accounts_AccountPrioritization = "T.Wrk.Acct.Prioritization",
    /** Limits users' ability to search and view accounts to only accounts associated with programs the user has access to via team membership or by being on a work group that participates in a program. If the user has access to any program associated with the account, the user will be able to see everything about the account including information from other programs. This feature does NOT limit access to information in Insurity BI. Dashboards, if enabled, may include information from other programs. */
    Flag_Work_Accounts_ExplicitProgramAccess = "T.Wrk.Acct.ExplctPrgmAccess",
    /** Removes the restriction on manual user data entry in application fields while a data import is running in the background. This can impact application fields where an integration is expected to import data and a user updates the field during the data import. */
    Flag_Work_Accounts_AllowManualFieldUpdatesDuringImport = "T.Wrk.Acct.FldImp",
    /** Enables Email Management within the workbench. */
    Flag_Work_Accounts_EmailManagement = "T.Wrk.Acct.EmailMgmt",
    /** New Account Overview to display by Program */
    Flag_Work_Accounts_PolicyInformationOverview = "T.Wrk.Acct.PolInfoView",
    /** Execute operations required by account workflows */
    Work_Workflows = "T.Wrk.WrkFlw",
    /** Lock, unlock and perform tasks. Select and rate products */
    Work_Workflows_ExecuteTask = "T.Wrk.WrkFlw.Exe",
    /** Stop an Executing or Completing Task */
    Work_Workflows_StopAnExecutingOrCompletingTask = "T.Wrk.WrkFlw.Stop",
    /** Assign team members to an account role */
    Work_Workflows_AssignAccountRoles = "T.Wrk.WrkFlw.Asgn",
    /** Enable Enhanced Data Store import feature */
    Flag_Work_Workflows_EnhancedDataStoreImport = "T.Wrk.WrkFlw.EnhDSImp",
    /** Allows the queueing of provisioning workflows while doing an integration. This can help limit system activity when starting batches of workflows for new accounts or renewals. */
    Flag_Work_Workflows_QueueWorkflowProvisioning = "T.Wrk.WrkFlw.QueueWfProv",
    /** Prune data import to determine if any data is redundant */
    Flag_Work_Workflows_OptimizeDataImport = "T.Wrk.WrkFlw.OpDSImp",
    /** Allows creating multiple quote scenarios for the insured. */
    Flag_Work_Workflows_CopyToNewQuoteVersion = "T.Wrk.WrkFlw.Copy",
    /** Manage documents in an account's document file */
    Work_Documents = "T.Wrk.Docs",
    /** Add a new document to the document file */
    Work_Documents_AddDocuments = "T.Wrk.Docs.Add",
    /** Edit existing document information */
    Work_Documents_EditDocuments = "T.Wrk.Docs.Edt",
    /** Delete a document from the document file */
    Work_Documents_DeleteADocument = "T.Wrk.Docs.Del",
    /** Manually update the documents' ExtractionStatus property */
    Work_Documents_UpdateExtractionstatus = "T.Wrk.Docs.Extract",
    /** Automated extraction and analysis of data from documents, streamlining information retrieval and enhancing efficiency in document-centric workflows. */
    Flag_Work_Documents_InsurityDocumentIntelligence = "T.Wrk.Docs.IDP",
    /** Allows users to edit documents in Box.com and access to document version history. */
    Flag_Work_Documents_BoxComEnhancements = "T.Wrk.Docs.Box.Enh",
    /** View reports */
    Work_Reports = "T.Wrk.Reports",

    /** Bill accounts */
    Billing = "T.Bill",
    /** View account balances */
    Billing_Balances = "T.Bill.Bal",
    /** Make changes to account balances */
    Billing_Balances_Modify = "T.Bill.Bal.Mod",
    /** Invoice Management */
    Billing_Invoicing = "T.Bill.Inv",
    /** Post, modify or revert invoices */
    Billing_Invoicing_Modify = "T.Bill.Inv.Mod",

}
