import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import _ from 'lodash';

import { BaseService } from '@Services/base-service';
import { GlobalMessages } from '@Services/global-messages';
import { LoggingService } from '@Services/logging-service';
import { LoadingService } from '@Services/loading-service';
import { ITypedServiceResponse } from '@Core/Lib/model';

import { DataContext } from '@Core/Lib/Contexts/data-context';
import * as Models from '@Core/CodeGen/Models/configuration.models';

class Response extends HttpResponse<any> { }

@Injectable()
export class ProgramsService extends BaseService {

    public static baseUrl: string = "";

    constructor(protected http: HttpClient,
                protected globalMessages: GlobalMessages,
                protected loggingService: LoggingService,
                protected loadingService: LoadingService) {
        super(globalMessages, loggingService, loadingService);
        ProgramsService.baseUrl = BaseService.baseUrl + "/Programs/";
    }

    public ListAccountRolesForProgram(contexts: DataContext[] | DataContext, programId: string): Observable<ITypedServiceResponse<Models.AccountRole>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/AccountRoles";
        url = url.replace("{programId}", programId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.AccountRole, request, context);
        });
        return request;
    }

    public RetrieveAnAccountRole(contexts: DataContext[] | DataContext, accountRoleId: string): Observable<ITypedServiceResponse<Models.AccountRole>> {
        // Build the url
        var url = ProgramsService.baseUrl + "AccountRoles/{accountRoleId}";
        url = url.replace("{accountRoleId}", accountRoleId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.AccountRole, request, context);
        });
        return request;
    }

    public AppointAccountRoleToProgram(body: any, contexts: DataContext[] | DataContext, programId: string): Observable<ITypedServiceResponse<Models.Program>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/ProgramObligatesAccountRoles";
        url = url.replace("{programId}", programId);

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Program, request, context);
        });
        return request;
    }

    public ModifyAnAccountRole(body: any, contexts: DataContext[] | DataContext, programId: string, accountRoleEdgeId: string): Observable<ITypedServiceResponse<Models.Program>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/ProgramObligatesAccountRoles/{accountRoleEdgeId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{accountRoleEdgeId}", accountRoleEdgeId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Program, request, context);
        });
        return request;
    }

    public RemoveAccountRoleFromProgram(idOfDeletedItem: string, contexts: DataContext[] | DataContext, programId: string, accountRoleEdgeId: string): Observable<ITypedServiceResponse<Models.Program>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/ProgramObligatesAccountRoles/{accountRoleEdgeId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{accountRoleEdgeId}", accountRoleEdgeId);

        // Make the request
        let request = this.http.delete<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public ListPrograms(contexts: DataContext[] | DataContext,
        optionalParameters: {
            published?: string
        }): Observable<ITypedServiceResponse<Models.Program>> {
        // Build the url
        var url = ProgramsService.baseUrl + "";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.published != null)
            params = params.set('published', optionalParameters.published);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Program, request, context);
        });
        return request;
    }

    public RetrieveProgram(contexts: DataContext[] | DataContext, programId: string): Observable<ITypedServiceResponse<Models.Program>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}";
        url = url.replace("{programId}", programId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Program, request, context);
        });
        return request;
    }

    public AddProgram(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.Program>> {
        // Build the url
        var url = ProgramsService.baseUrl + "";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Program, request, context);
        });
        return request;
    }

    public ModifyProgram(body: any, contexts: DataContext[] | DataContext, programId: string,
        optionalParameters: {
            deploy?: string,
            changeId?: string
        }): Observable<ITypedServiceResponse<Models.Program>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}";
        url = url.replace("{programId}", programId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.deploy != null)
            params = params.set('deploy', optionalParameters.deploy);
        if (optionalParameters?.changeId != null)
            params = params.set('changeId', optionalParameters.changeId);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Program, request, context);
        });
        return request;
    }

    public ListProducersOfferingTheProgram(contexts: DataContext[] | DataContext, programId: string): Observable<ITypedServiceResponse<Models.Producer>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Producers";
        url = url.replace("{programId}", programId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Producer, request, context);
        });
        return request;
    }

    public ListChanges(contexts: DataContext[] | DataContext, programId: string,
        optionalParameters: {
            status?: string,
            lockedBy?: string,
            includeReleased?: string
        }): Observable<ITypedServiceResponse<Models.ProgramChange>> {
        // Build the url
        var url = ProgramsService.baseUrl + "Changes";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('programId', programId);
        if (optionalParameters?.status != null)
            params = params.set('status', optionalParameters.status);
        if (optionalParameters?.lockedBy != null)
            params = params.set('lockedBy', optionalParameters.lockedBy);
        if (optionalParameters?.includeReleased != null)
            params = params.set('includeReleased', optionalParameters.includeReleased);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.ProgramChange, request, context);
        });
        return request;
    }

    public RetrieveChange(contexts: DataContext[] | DataContext, changeId: string): Observable<ITypedServiceResponse<Models.ProgramChange>> {
        // Build the url
        var url = ProgramsService.baseUrl + "Changes/{changeId}";
        url = url.replace("{changeId}", changeId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.ProgramChange, request, context);
        });
        return request;
    }

    public ModifyChange(body: any, contexts: DataContext[] | DataContext, changeId: string): Observable<ITypedServiceResponse<Models.ProgramChange>> {
        // Build the url
        var url = ProgramsService.baseUrl + "Changes/{changeId}";
        url = url.replace("{changeId}", changeId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.ProgramChange, request, context);
        });
        return request;
    }

    public LockChange(body: any, contexts: DataContext[] | DataContext, changeId: string): Observable<ITypedServiceResponse<Models.ProgramChange>> {
        // Build the url
        var url = ProgramsService.baseUrl + "Changes/{changeId}/Lock";
        url = url.replace("{changeId}", changeId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.ProgramChange, request, context);
        });
        return request;
    }

    public UnlockChange(body: any, contexts: DataContext[] | DataContext, changeId: string): Observable<ITypedServiceResponse<Models.ProgramChange>> {
        // Build the url
        var url = ProgramsService.baseUrl + "Changes/{changeId}/Unlock";
        url = url.replace("{changeId}", changeId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.ProgramChange, request, context);
        });
        return request;
    }

    public StealChangeLock(body: any, contexts: DataContext[] | DataContext, changeId: string): Observable<ITypedServiceResponse<Models.ProgramChange>> {
        // Build the url
        var url = ProgramsService.baseUrl + "Changes/{changeId}/Steal";
        url = url.replace("{changeId}", changeId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.ProgramChange, request, context);
        });
        return request;
    }

    public CommitChanges(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.ProgramChange>> {
        // Build the url
        var url = ProgramsService.baseUrl + "Commit";

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.ProgramChange, request, context);
        });
        return request;
    }

    public UncommitChanges(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.ProgramChange>> {
        // Build the url
        var url = ProgramsService.baseUrl + "Uncommit";

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.ProgramChange, request, context);
        });
        return request;
    }

    public StartChange(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.ProgramChange>> {
        // Build the url
        var url = ProgramsService.baseUrl + "Changes";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.ProgramChange, request, context);
        });
        return request;
    }

    public RevertChange(body: any, contexts: DataContext[] | DataContext, changeId: string): Observable<ITypedServiceResponse<Models.ProgramChange>> {
        // Build the url
        var url = ProgramsService.baseUrl + "Changes/{changeId}/Revert";
        url = url.replace("{changeId}", changeId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.ProgramChange, request, context);
        });
        return request;
    }

    public StartPatch(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.ProgramPatch>> {
        // Build the url
        var url = ProgramsService.baseUrl + "Patches";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.ProgramPatch, request, context);
        });
        return request;
    }

    public ListProgramPatches(contexts: DataContext[] | DataContext, programId: string,
        optionalParameters: {
            status?: string,
            lockedBy?: string,
            includeReleased?: string
        }): Observable<ITypedServiceResponse<Models.ProgramPatch>> {
        // Build the url
        var url = ProgramsService.baseUrl + "Patches";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('programId', programId);
        if (optionalParameters?.status != null)
            params = params.set('status', optionalParameters.status);
        if (optionalParameters?.lockedBy != null)
            params = params.set('lockedBy', optionalParameters.lockedBy);
        if (optionalParameters?.includeReleased != null)
            params = params.set('includeReleased', optionalParameters.includeReleased);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.ProgramPatch, request, context);
        });
        return request;
    }

    public RetrieveProgramPatch(contexts: DataContext[] | DataContext, patchId: string): Observable<ITypedServiceResponse<Models.ProgramPatch>> {
        // Build the url
        var url = ProgramsService.baseUrl + "Patches/{patchId}";
        url = url.replace("{patchId}", patchId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.ProgramPatch, request, context);
        });
        return request;
    }

    public DeployAProgramPatch(body: any, contexts: DataContext[] | DataContext, patchId: string, areaKey: string): Observable<ITypedServiceResponse<Models.ProgramPatch>> {
        // Build the url
        var url = ProgramsService.baseUrl + "Patches/{patchId}/Deploy";
        url = url.replace("{patchId}", patchId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('areaKey', areaKey);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.ProgramPatch, request, context);
        });
        return request;
    }

    public RetrieveStatusOfProgramPatchDeployment(contexts: DataContext[] | DataContext, patchId: string): Observable<ITypedServiceResponse<Models.Program>> {
        // Build the url
        var url = ProgramsService.baseUrl + "Patches/{patchId}/DeploymentStatus";
        url = url.replace("{patchId}", patchId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Program, request, context);
        });
        return request;
    }

    public UndeployProgramPatch(body: any, contexts: DataContext[] | DataContext, patchId: string): Observable<ITypedServiceResponse<Models.ProgramPatch>> {
        // Build the url
        var url = ProgramsService.baseUrl + "Patches/{patchId}/Undeploy";
        url = url.replace("{patchId}", patchId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.ProgramPatch, request, context);
        });
        return request;
    }

    public ListRevisions(contexts: DataContext[] | DataContext,
        optionalParameters: {
            programId?: string
        }): Observable<ITypedServiceResponse<Models.ProgramRevision>> {
        // Build the url
        var url = ProgramsService.baseUrl + "Revisions";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.programId != null)
            params = params.set('programId', optionalParameters.programId);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.ProgramRevision, request, context);
        });
        return request;
    }

    public RetrieveRevision(contexts: DataContext[] | DataContext, revisionId: string): Observable<ITypedServiceResponse<Models.ProgramRevision>> {
        // Build the url
        var url = ProgramsService.baseUrl + "Revisions/{revisionId}";
        url = url.replace("{revisionId}", revisionId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.ProgramRevision, request, context);
        });
        return request;
    }

    public RetrieveProgramConfiguration(programId: string,
        optionalParameters: {
            revision?: string,
            patch?: string
        }): Observable<Response> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Configuration";
        url = url.replace("{programId}", programId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.revision != null)
            params = params.set('revision', optionalParameters.revision);
        if (optionalParameters?.patch != null)
            params = params.set('patch', optionalParameters.patch);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ExportProgramRevision(revisionId: string): Observable<Response> {
        // Build the url
        var url = ProgramsService.baseUrl + "Revisions/{revisionId}/Export";
        url = url.replace("{revisionId}", revisionId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ImportProgram(body: any, contexts: DataContext[] | DataContext,
        optionalParameters: {
            programName?: string,
            description?: string
        }): Observable<ITypedServiceResponse<Models.Program>> {
        // Build the url
        var url = ProgramsService.baseUrl + "Import";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.programName != null)
            params = params.set('programName', optionalParameters.programName);
        if (optionalParameters?.description != null)
            params = params.set('description', optionalParameters.description);

        // Make the request
        let request = this.http.post<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Program, request, context);
        });
        return request;
    }

    public RetrieveStatusOfProgramImport(contexts: DataContext[] | DataContext, programId: string): Observable<ITypedServiceResponse<Models.Program>> {
        // Build the url
        var url = ProgramsService.baseUrl + "Import/Status/{programId}";
        url = url.replace("{programId}", programId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Program, request, context);
        });
        return request;
    }

    public AddChangesToAProgramRevision(body: any, contexts: DataContext[] | DataContext, revisionId: string): Observable<ITypedServiceResponse<Models.ProgramRevision>> {
        // Build the url
        var url = ProgramsService.baseUrl + "Revisions/{revisionId}/AddChanges";
        url = url.replace("{revisionId}", revisionId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.ProgramRevision, request, context);
        });
        return request;
    }

    public RemoveChangesFromAProgramRevision(body: any, contexts: DataContext[] | DataContext, revisionId: string): Observable<ITypedServiceResponse<Models.ProgramRevision>> {
        // Build the url
        var url = ProgramsService.baseUrl + "Revisions/{revisionId}/RemoveChanges";
        url = url.replace("{revisionId}", revisionId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.ProgramRevision, request, context);
        });
        return request;
    }

    public DeployAProgramRevision(body: any, contexts: DataContext[] | DataContext, revisionId: string, areaKey: string): Observable<ITypedServiceResponse<Models.ProgramRevision>> {
        // Build the url
        var url = ProgramsService.baseUrl + "Revisions/{revisionId}/Deploy";
        url = url.replace("{revisionId}", revisionId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('areaKey', areaKey);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.ProgramRevision, request, context);
        });
        return request;
    }

    public RetrieveStatusOfProgramRevisionDeployment(contexts: DataContext[] | DataContext, revisionId: string): Observable<ITypedServiceResponse<Models.Program>> {
        // Build the url
        var url = ProgramsService.baseUrl + "Revisions/{revisionId}/DeploymentStatus";
        url = url.replace("{revisionId}", revisionId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Program, request, context);
        });
        return request;
    }

    public UndeployProgramRevision(body: any, contexts: DataContext[] | DataContext, revisionId: string): Observable<ITypedServiceResponse<Models.ProgramRevision>> {
        // Build the url
        var url = ProgramsService.baseUrl + "Revisions/{revisionId}/Undeploy";
        url = url.replace("{revisionId}", revisionId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.ProgramRevision, request, context);
        });
        return request;
    }

    public StartNewProgramRevision(body: any, contexts: DataContext[] | DataContext, programId: string): Observable<ITypedServiceResponse<Models.ProgramRevision>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Revisions";
        url = url.replace("{programId}", programId);

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.ProgramRevision, request, context);
        });
        return request;
    }

    public ModifyAProgramRevision(body: any, contexts: DataContext[] | DataContext, programId: string, revisionId: string): Observable<ITypedServiceResponse<Models.ProgramRevision>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Revisions/{revisionId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{revisionId}", revisionId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.ProgramRevision, request, context);
        });
        return request;
    }

    public SetStandbyWorkflows(body: any, revisionId: string): Observable<Response> {
        // Build the url
        var url = ProgramsService.baseUrl + "Revisions/{revisionId}/SetStandbyWorkflows";
        url = url.replace("{revisionId}", revisionId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ValidateProgram(body: any, contexts: DataContext[] | DataContext, programId: string,
        optionalParameters: {
            changeId?: string,
            revisionId?: string
        }): Observable<ITypedServiceResponse<Models.Program>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Validate";
        url = url.replace("{programId}", programId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.changeId != null)
            params = params.set('changeId', optionalParameters.changeId);
        if (optionalParameters?.revisionId != null)
            params = params.set('revisionId', optionalParameters.revisionId);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Program, request, context);
        });
        return request;
    }

    public RetrieveStatusOfProgramValidation(contexts: DataContext[] | DataContext, programId: string): Observable<ITypedServiceResponse<Models.Program>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Validate/Status";
        url = url.replace("{programId}", programId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Program, request, context);
        });
        return request;
    }

    public StopProgramValidation(body: any, contexts: DataContext[] | DataContext, programId: string): Observable<ITypedServiceResponse<Models.Program>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Validate/Stop";
        url = url.replace("{programId}", programId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Program, request, context);
        });
        return request;
    }

    public ListValidationMessages(contexts: DataContext[] | DataContext, programId: string): Observable<ITypedServiceResponse<Models.ProgramValidationMessage>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/ValidationMessages";
        url = url.replace("{programId}", programId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.ProgramValidationMessage, request, context);
        });
        return request;
    }

    public ListValidationCodes(): Observable<Response> {
        // Build the url
        var url = ProgramsService.baseUrl + "ValidationCodes";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public DeployProgramTeam(body: any, contexts: DataContext[] | DataContext, programId: string): Observable<ITypedServiceResponse<Models.Program>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Team/Deploy";
        url = url.replace("{programId}", programId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Program, request, context);
        });
        return request;
    }

    public RetrieveDeployedProgramTeam(programId: string): Observable<Response> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Team";
        url = url.replace("{programId}", programId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ListWorkgroups(contexts: DataContext[] | DataContext, programId: string): Observable<ITypedServiceResponse<Models.WorkGroup>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/WorkGroups";
        url = url.replace("{programId}", programId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.WorkGroup, request, context);
        });
        return request;
    }

    public RetrieveWorkgroupUsers(contexts: DataContext[] | DataContext, programId: string, workgroupId: string): Observable<ITypedServiceResponse<Models.TenantUser>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/WorkGroups/{workgroupId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{workgroupId}", workgroupId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.TenantUser, request, context);
        });
        return request;
    }

    public AddWorkGroupToProgram(body: any, contexts: DataContext[] | DataContext, programId: string): Observable<ITypedServiceResponse<Models.Program>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/ProgramUsesWorkGroup";
        url = url.replace("{programId}", programId);

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Program, request, context);
        });
        return request;
    }

    public ModifyAWorkGroupOnAProgram(body: any, contexts: DataContext[] | DataContext, programId: string, usesEdgeId: string): Observable<ITypedServiceResponse<Models.Program>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/ProgramUsesWorkGroup/{usesEdgeId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{usesEdgeId}", usesEdgeId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Program, request, context);
        });
        return request;
    }

    public RemoveWorkGroupFromProgram(idOfDeletedItem: string, contexts: DataContext[] | DataContext, programId: string, usesEdgeId: string): Observable<ITypedServiceResponse<Models.Program>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/ProgramUsesWorkGroup/{usesEdgeId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{usesEdgeId}", usesEdgeId);

        // Make the request
        let request = this.http.delete<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public ListTenantUsers(contexts: DataContext[] | DataContext, programId: string): Observable<ITypedServiceResponse<Models.TenantUser>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/TenantUsers";
        url = url.replace("{programId}", programId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.TenantUser, request, context);
        });
        return request;
    }

    public RetrieveTeamMember(contexts: DataContext[] | DataContext, programId: string, identityKey: string): Observable<ITypedServiceResponse<Models.TenantUser>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/TeamMembers/{identityKey}";
        url = url.replace("{programId}", programId);
        url = url.replace("{identityKey}", identityKey);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.TenantUser, request, context);
        });
        return request;
    }

    public AddTeamMembers(body: any, contexts: DataContext[] | DataContext, programId: string): Observable<ITypedServiceResponse<Models.Program>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/ProgramEmploysTenantUser";
        url = url.replace("{programId}", programId);

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Program, request, context);
        });
        return request;
    }

    public ModifyTeamMembers(body: any, contexts: DataContext[] | DataContext, programId: string, employsId: string): Observable<ITypedServiceResponse<Models.Program>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/ProgramEmploysTenantUser/{employsId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{employsId}", employsId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Program, request, context);
        });
        return request;
    }

    public RemoveTeamMember(idOfDeletedItem: string, contexts: DataContext[] | DataContext, programId: string, employsId: string): Observable<ITypedServiceResponse<Models.Program>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/ProgramEmploysTenantUser/{employsId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{employsId}", employsId);

        // Make the request
        let request = this.http.delete<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public ListIdentities(ids: string): Observable<Response> {
        // Build the url
        var url = ProgramsService.baseUrl + "Identities";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('ids', ids);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ListApplications(contexts: DataContext[] | DataContext, programId: string): Observable<ITypedServiceResponse<Models.Application>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Applications";
        url = url.replace("{programId}", programId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Application, request, context);
        });
        return request;
    }

    public AddApplication(body: any, contexts: DataContext[] | DataContext, programId: string, changeId: string): Observable<ITypedServiceResponse<Models.Application>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Applications";
        url = url.replace("{programId}", programId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.post<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Application, request, context);
        });
        return request;
    }

    public ModifyApplication(body: any, contexts: DataContext[] | DataContext, programId: string, applicationId: string, changeId: string): Observable<ITypedServiceResponse<Models.Application>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Applications/{applicationId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{applicationId}", applicationId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Application, request, context);
        });
        return request;
    }

    public RetrieveApplication(contexts: DataContext[] | DataContext, programId: string, applicationId: string): Observable<ITypedServiceResponse<Models.Application>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Applications/{applicationId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{applicationId}", applicationId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Application, request, context);
        });
        return request;
    }

    public RemoveApplication(idOfDeletedItem: string, contexts: DataContext[] | DataContext, programId: string, applicationId: string, changeId: string): Observable<Response> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Applications/{applicationId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{applicationId}", applicationId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.delete<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public ListSections(contexts: DataContext[] | DataContext, programId: string,
        optionalParameters: {
            applicationId?: string
        }): Observable<ITypedServiceResponse<Models.Section>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Sections";
        url = url.replace("{programId}", programId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.applicationId != null)
            params = params.set('applicationId', optionalParameters.applicationId);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Section, request, context);
        });
        return request;
    }

    public RetrieveSection(contexts: DataContext[] | DataContext, programId: string, sectionId: string): Observable<ITypedServiceResponse<Models.Section>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Sections/{sectionId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{sectionId}", sectionId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Section, request, context);
        });
        return request;
    }

    public RetrieveElement(contexts: DataContext[] | DataContext, programId: string, elementId: string): Observable<ITypedServiceResponse<Models.Element>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Elements/{elementId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{elementId}", elementId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Element, request, context);
        });
        return request;
    }

    public AddElement(body: any, contexts: DataContext[] | DataContext, programId: string, applicationId: string, changeId: string,
        optionalParameters: {
            afterId?: string
        }): Observable<ITypedServiceResponse<Models.Element>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Applications/{applicationId}/Elements";
        url = url.replace("{programId}", programId);
        url = url.replace("{applicationId}", applicationId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);
        if (optionalParameters?.afterId != null)
            params = params.set('afterId', optionalParameters.afterId);

        // Make the request
        let request = this.http.post<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Element, request, context);
        });
        return request;
    }

    public ModifyAnApplicationElement(body: any, contexts: DataContext[] | DataContext, programId: string, elementId: string, changeId: string): Observable<ITypedServiceResponse<Models.Element>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Elements/{elementId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{elementId}", elementId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Element, request, context);
        });
        return request;
    }

    public RemoveApplicationElement(idOfDeletedItem: string, contexts: DataContext[] | DataContext, programId: string, elementId: string, changeId: string): Observable<Response> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Elements/{elementId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{elementId}", elementId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.delete<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public MoveElement(body: any, programId: string, elementId: string, changeId: string, before: string): Observable<Response> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Elements/{elementId}/MoveElement";
        url = url.replace("{programId}", programId);
        url = url.replace("{elementId}", elementId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);
        params = params.set('before', before);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ListStaticElementTypes(): Observable<Response> {
        // Build the url
        var url = ProgramsService.baseUrl + "StaticElementTypes";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ListAssetGroups(contexts: DataContext[] | DataContext, programId: string): Observable<ITypedServiceResponse<Models.AssetGroup>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/AssetGroups";
        url = url.replace("{programId}", programId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.AssetGroup, request, context);
        });
        return request;
    }

    public RetrieveAssetGroup(contexts: DataContext[] | DataContext, programId: string, assetGroupId: string): Observable<ITypedServiceResponse<Models.AssetGroup>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/AssetGroups/{assetGroupId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{assetGroupId}", assetGroupId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.AssetGroup, request, context);
        });
        return request;
    }

    public AddAssetGroup(body: any, contexts: DataContext[] | DataContext, programId: string, changeId: string): Observable<ITypedServiceResponse<Models.AssetGroup>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/AssetGroups";
        url = url.replace("{programId}", programId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.post<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.AssetGroup, request, context);
        });
        return request;
    }

    public ModifyAnAssetGroup(body: any, contexts: DataContext[] | DataContext, programId: string, assetGroupId: string, changeId: string): Observable<ITypedServiceResponse<Models.AssetGroup>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/AssetGroups/{assetGroupId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{assetGroupId}", assetGroupId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.AssetGroup, request, context);
        });
        return request;
    }

    public ListAssets(contexts: DataContext[] | DataContext, programId: string,
        optionalParameters: {
            assetGroupId?: string
        }): Observable<ITypedServiceResponse<Models.Asset>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Assets";
        url = url.replace("{programId}", programId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.assetGroupId != null)
            params = params.set('assetGroupId', optionalParameters.assetGroupId);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Asset, request, context);
        });
        return request;
    }

    public RetrieveAnAsset(contexts: DataContext[] | DataContext, programId: string, assetId: string): Observable<ITypedServiceResponse<Models.Asset>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Assets/{assetId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{assetId}", assetId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Asset, request, context);
        });
        return request;
    }

    public DetermineAssetPayloadState(contexts: DataContext[] | DataContext, programId: string, assetId: string, changeId: string): Observable<ITypedServiceResponse<Models.Asset>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Assets/{assetId}/Upload";
        url = url.replace("{programId}", programId);
        url = url.replace("{assetId}", assetId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Asset, request, context);
        });
        return request;
    }

    public RetrieveStatusOfAssetPayloadUpload(contexts: DataContext[] | DataContext, programId: string, assetId: string): Observable<ITypedServiceResponse<Models.Asset>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Assets/{assetId}/Upload/Status";
        url = url.replace("{programId}", programId);
        url = url.replace("{assetId}", assetId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Asset, request, context);
        });
        return request;
    }

    public RetrieveAssetContent(programId: string, assetId: string,
        optionalParameters: {
            revisionId?: string
        }): Observable<Response> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Assets/{assetId}/Content";
        url = url.replace("{programId}", programId);
        url = url.replace("{assetId}", assetId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.revisionId != null)
            params = params.set('revisionId', optionalParameters.revisionId);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public InitializeANewAsset(body: any, contexts: DataContext[] | DataContext, programId: string, assetGroupId: string, changeId: string): Observable<ITypedServiceResponse<Models.Asset>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/AssetGroup/{assetGroupId}/Assets";
        url = url.replace("{programId}", programId);
        url = url.replace("{assetGroupId}", assetGroupId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.post<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Asset, request, context);
        });
        return request;
    }

    public UploadThePayloadToAnAsset(body: any, contexts: DataContext[] | DataContext, programId: string, assetGroupId: string, assetId: string, changeId: string): Observable<ITypedServiceResponse<Models.Asset>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/AssetGroup/{assetGroupId}/Assets/{assetId}/Upload";
        url = url.replace("{programId}", programId);
        url = url.replace("{assetGroupId}", assetGroupId);
        url = url.replace("{assetId}", assetId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.post<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Asset, request, context);
        });
        return request;
    }

    public UpdatePropertiesOfAnAsset(body: any, contexts: DataContext[] | DataContext, programId: string, assetGroupId: string, assetId: string, changeId: string,
        optionalParameters: {
            replace?: string
        }): Observable<ITypedServiceResponse<Models.Asset>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/AssetGroup/{assetGroupId}/Assets/{assetId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{assetGroupId}", assetGroupId);
        url = url.replace("{assetId}", assetId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);
        if (optionalParameters?.replace != null)
            params = params.set('replace', optionalParameters.replace);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Asset, request, context);
        });
        return request;
    }

    public ListDataPanels(contexts: DataContext[] | DataContext, programId: string): Observable<ITypedServiceResponse<Models.DataPanel>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/DataPanels";
        url = url.replace("{programId}", programId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.DataPanel, request, context);
        });
        return request;
    }

    public ListWidgets(contexts: DataContext[] | DataContext, programId: string,
        optionalParameters: {
            dataPanelId?: string
        }): Observable<ITypedServiceResponse<Models.Widget>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Widgets";
        url = url.replace("{programId}", programId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.dataPanelId != null)
            params = params.set('dataPanelId', optionalParameters.dataPanelId);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Widget, request, context);
        });
        return request;
    }

    public ListDataGroups(contexts: DataContext[] | DataContext, programId: string, dataStoreId: string,
        optionalParameters: {
            depth?: string
        }): Observable<ITypedServiceResponse<Models.DataGroup>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/DataGroups";
        url = url.replace("{programId}", programId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('dataStoreId', dataStoreId);
        if (optionalParameters?.depth != null)
            params = params.set('depth', optionalParameters.depth);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.DataGroup, request, context);
        });
        return request;
    }

    public RetrieveDataGroup(contexts: DataContext[] | DataContext, programId: string, dataGroupId: string,
        optionalParameters: {
            details?: string
        }): Observable<ITypedServiceResponse<Models.DataGroup>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/DataGroups/{dataGroupId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{dataGroupId}", dataGroupId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.details != null)
            params = params.set('details', optionalParameters.details);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.DataGroup, request, context);
        });
        return request;
    }

    public AddDataGroup(body: any, contexts: DataContext[] | DataContext, programId: string, dataStoreId: string, changeId: string): Observable<ITypedServiceResponse<Models.DataGroup>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/DataStores/{dataStoreId}/DataGroups";
        url = url.replace("{programId}", programId);
        url = url.replace("{dataStoreId}", dataStoreId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.post<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.DataGroup, request, context);
        });
        return request;
    }

    public ModifyDataGroups(body: any, contexts: DataContext[] | DataContext, programId: string, changeId: string): Observable<ITypedServiceResponse<Models.DataGroup>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/DataGroups";
        url = url.replace("{programId}", programId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.DataGroup, request, context);
        });
        return request;
    }

    public RemoveDataGroup(idOfDeletedItem: string, contexts: DataContext[] | DataContext, programId: string, dataGroupId: string, changeId: string): Observable<Response> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/DataGroups/{dataGroupId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{dataGroupId}", dataGroupId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.delete<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public RetrieveDataPoint(contexts: DataContext[] | DataContext, programId: string, dataPointId: string): Observable<ITypedServiceResponse<Models.DataPoint>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/DataPoints/{dataPointId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{dataPointId}", dataPointId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.DataPoint, request, context);
        });
        return request;
    }

    public ListDataPoints(contexts: DataContext[] | DataContext, programId: string,
        optionalParameters: {
            dataGroupId?: string,
            dataStoreId?: string,
            name?: string,
            revisionId?: string,
            depth?: string
        }): Observable<ITypedServiceResponse<Models.DataPoint>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/DataPoints";
        url = url.replace("{programId}", programId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.dataGroupId != null)
            params = params.set('dataGroupId', optionalParameters.dataGroupId);
        if (optionalParameters?.dataStoreId != null)
            params = params.set('dataStoreId', optionalParameters.dataStoreId);
        if (optionalParameters?.name != null)
            params = params.set('name', optionalParameters.name);
        if (optionalParameters?.revisionId != null)
            params = params.set('revisionId', optionalParameters.revisionId);
        if (optionalParameters?.depth != null)
            params = params.set('depth', optionalParameters.depth);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.DataPoint, request, context);
        });
        return request;
    }

    public AddDataPoints(body: any, contexts: DataContext[] | DataContext, programId: string, dataStoreId: string, changeId: string): Observable<ITypedServiceResponse<Models.DataPoint>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/DataStores/{dataStoreId}/DataPoints";
        url = url.replace("{programId}", programId);
        url = url.replace("{dataStoreId}", dataStoreId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.post<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.DataPoint, request, context);
        });
        return request;
    }

    public ModifyDataPoints(body: any, contexts: DataContext[] | DataContext, programId: string, changeId: string): Observable<ITypedServiceResponse<Models.DataPoint>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/DataPoints";
        url = url.replace("{programId}", programId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.DataPoint, request, context);
        });
        return request;
    }

    public RemoveDataPoint(idOfDeletedItem: string, contexts: DataContext[] | DataContext, programId: string, dataPointId: string, changeId: string): Observable<Response> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/DataPoints/{dataPointId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{dataPointId}", dataPointId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.delete<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public TestScriptCondition(body: any, programId: string): Observable<Response> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/TestScript";
        url = url.replace("{programId}", programId);

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ListDataStores(contexts: DataContext[] | DataContext, programId: string): Observable<ITypedServiceResponse<Models.DataStore>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/DataStores";
        url = url.replace("{programId}", programId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.DataStore, request, context);
        });
        return request;
    }

    public RetrieveDataStore(contexts: DataContext[] | DataContext, programId: string, dataStoreId: string): Observable<ITypedServiceResponse<Models.DataStore>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/DataStores/{dataStoreId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{dataStoreId}", dataStoreId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.DataStore, request, context);
        });
        return request;
    }

    public ListDataPointTypes(): Observable<Response> {
        // Build the url
        var url = ProgramsService.baseUrl + "DataPointTypes";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ListDataGroupsTypes(): Observable<Response> {
        // Build the url
        var url = ProgramsService.baseUrl + "DataGroupTypes";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public AddDataStore(body: any, contexts: DataContext[] | DataContext, programId: string, changeId: string): Observable<ITypedServiceResponse<Models.DataStore>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/DataStores";
        url = url.replace("{programId}", programId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.post<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.DataStore, request, context);
        });
        return request;
    }

    public ModifyDataStore(body: any, contexts: DataContext[] | DataContext, programId: string, changeId: string): Observable<ITypedServiceResponse<Models.DataStore>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/DataStores";
        url = url.replace("{programId}", programId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.DataStore, request, context);
        });
        return request;
    }

    public RemoveDataStore(idOfDeletedItem: string, contexts: DataContext[] | DataContext, programId: string, dataStoreId: string, changeId: string): Observable<Response> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/DataStores/{dataStoreId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{dataStoreId}", dataStoreId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.delete<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public ListRules(contexts: DataContext[] | DataContext, programId: string, ruleSetId: string): Observable<ITypedServiceResponse<Models.Rule>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Rules";
        url = url.replace("{programId}", programId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('ruleSetId', ruleSetId);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Rule, request, context);
        });
        return request;
    }

    public RetrieveRule(contexts: DataContext[] | DataContext, programId: string, ruleId: string): Observable<ITypedServiceResponse<Models.Rule>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Rules/{ruleId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{ruleId}", ruleId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Rule, request, context);
        });
        return request;
    }

    public AddRule(body: any, contexts: DataContext[] | DataContext, programId: string, ruleSetId: string, changeId: string): Observable<ITypedServiceResponse<Models.Rule>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/RuleSets/{ruleSetId}/Rules";
        url = url.replace("{programId}", programId);
        url = url.replace("{ruleSetId}", ruleSetId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.post<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Rule, request, context);
        });
        return request;
    }

    public ModifyRule(body: any, contexts: DataContext[] | DataContext, programId: string, ruleId: string, changeId: string): Observable<ITypedServiceResponse<Models.Rule>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Rules/{ruleId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{ruleId}", ruleId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Rule, request, context);
        });
        return request;
    }

    public RemoveRule(idOfDeletedItem: string, contexts: DataContext[] | DataContext, programId: string, ruleId: string, changeId: string): Observable<Response> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Rules/{ruleId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{ruleId}", ruleId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.delete<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public ListRulesets(contexts: DataContext[] | DataContext, programId: string): Observable<ITypedServiceResponse<Models.RuleSet>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/RuleSets";
        url = url.replace("{programId}", programId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.RuleSet, request, context);
        });
        return request;
    }

    public RetrieveRuleset(contexts: DataContext[] | DataContext, programId: string, ruleSetId: string): Observable<ITypedServiceResponse<Models.RuleSet>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/RuleSets/{ruleSetId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{ruleSetId}", ruleSetId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.RuleSet, request, context);
        });
        return request;
    }

    public AddRuleset(body: any, contexts: DataContext[] | DataContext, programId: string, changeId: string): Observable<ITypedServiceResponse<Models.RuleSet>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/RuleSets";
        url = url.replace("{programId}", programId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.post<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.RuleSet, request, context);
        });
        return request;
    }

    public ModifyRuleset(body: any, contexts: DataContext[] | DataContext, programId: string, ruleSetId: string, changeId: string): Observable<ITypedServiceResponse<Models.RuleSet>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/RuleSets/{ruleSetId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{ruleSetId}", ruleSetId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.RuleSet, request, context);
        });
        return request;
    }

    public RemoveRuleset(idOfDeletedItem: string, contexts: DataContext[] | DataContext, programId: string, ruleSetId: string, changeId: string): Observable<Response> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/RuleSets/{ruleSetId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{ruleSetId}", ruleSetId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.delete<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public ListPhases(contexts: DataContext[] | DataContext, programId: string, workflowId: string): Observable<ITypedServiceResponse<Models.Phase>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Phases";
        url = url.replace("{programId}", programId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('workflowId', workflowId);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Phase, request, context);
        });
        return request;
    }

    public RetrievePhase(contexts: DataContext[] | DataContext, programId: string, phaseId: string): Observable<ITypedServiceResponse<Models.Phase>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Phases/{phaseId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{phaseId}", phaseId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Phase, request, context);
        });
        return request;
    }

    public AddPhase(body: any, contexts: DataContext[] | DataContext, programId: string, workflowId: string, changeId: string,
        optionalParameters: {
            before?: string
        }): Observable<ITypedServiceResponse<Models.Phase>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Workflows/{workflowId}/Phases";
        url = url.replace("{programId}", programId);
        url = url.replace("{workflowId}", workflowId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);
        if (optionalParameters?.before != null)
            params = params.set('before', optionalParameters.before);

        // Make the request
        let request = this.http.post<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Phase, request, context);
        });
        return request;
    }

    public ModifyPhase(body: any, contexts: DataContext[] | DataContext, programId: string, phaseId: string, changeId: string): Observable<ITypedServiceResponse<Models.Phase>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Phases/{phaseId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{phaseId}", phaseId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Phase, request, context);
        });
        return request;
    }

    public MovePhase(body: any, contexts: DataContext[] | DataContext, programId: string, phaseId: string, changeId: string, before: string): Observable<ITypedServiceResponse<Models.Workflow>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Phases/{phaseId}/MovePhase";
        url = url.replace("{programId}", programId);
        url = url.replace("{phaseId}", phaseId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);
        params = params.set('before', before);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Workflow, request, context);
        });
        return request;
    }

    public DeletePhase(idOfDeletedItem: string, contexts: DataContext[] | DataContext, programId: string, phaseId: string, changeId: string): Observable<ITypedServiceResponse<Models.Phase>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Phases/{phaseId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{phaseId}", phaseId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.delete<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public ListPublishedTasks(contexts: DataContext[] | DataContext, programId: string,
        optionalParameters: {
            workflowSetId?: string,
            workflowId?: string,
            phaseId?: string,
            revisionId?: string
        }): Observable<ITypedServiceResponse<Models.Task>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Tasks";
        url = url.replace("{programId}", programId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.workflowSetId != null)
            params = params.set('workflowSetId', optionalParameters.workflowSetId);
        if (optionalParameters?.workflowId != null)
            params = params.set('workflowId', optionalParameters.workflowId);
        if (optionalParameters?.phaseId != null)
            params = params.set('phaseId', optionalParameters.phaseId);
        if (optionalParameters?.revisionId != null)
            params = params.set('revisionId', optionalParameters.revisionId);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Task, request, context);
        });
        return request;
    }

    public RetrieveTask(contexts: DataContext[] | DataContext, programId: string, taskId: string): Observable<ITypedServiceResponse<Models.Task>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Tasks/{taskId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{taskId}", taskId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Task, request, context);
        });
        return request;
    }

    public AddTasks(body: any, contexts: DataContext[] | DataContext, programId: string, workflowId: string, changeId: string): Observable<ITypedServiceResponse<Models.Task>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Workflows/{workflowId}/Tasks";
        url = url.replace("{programId}", programId);
        url = url.replace("{workflowId}", workflowId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.post<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Task, request, context);
        });
        return request;
    }

    public ModifyTask(body: any, contexts: DataContext[] | DataContext, programId: string, taskId: string,
        optionalParameters: {
            changeId?: string,
            patchId?: string,
            workflowId?: string
        }): Observable<ITypedServiceResponse<Models.Task>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Tasks/{taskId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{taskId}", taskId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.changeId != null)
            params = params.set('changeId', optionalParameters.changeId);
        if (optionalParameters?.patchId != null)
            params = params.set('patchId', optionalParameters.patchId);
        if (optionalParameters?.workflowId != null)
            params = params.set('workflowId', optionalParameters.workflowId);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Task, request, context);
        });
        return request;
    }

    public DeleteTask(idOfDeletedItem: string, contexts: DataContext[] | DataContext, programId: string, taskId: string, changeId: string): Observable<Response> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Tasks/{taskId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{taskId}", taskId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.delete<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public MoveTask(body: any, contexts: DataContext[] | DataContext, programId: string, taskId: string, changeId: string, before: string): Observable<ITypedServiceResponse<Models.Phase>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Tasks/{taskId}/MoveTask";
        url = url.replace("{programId}", programId);
        url = url.replace("{taskId}", taskId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);
        params = params.set('before', before);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Phase, request, context);
        });
        return request;
    }

    public ListPolicyRatedStatuses(): Observable<Response> {
        // Build the url
        var url = ProgramsService.baseUrl + "PolicyRatedStatuses";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public RetrieveWorkflow(contexts: DataContext[] | DataContext, programId: string, workflowId: string): Observable<ITypedServiceResponse<Models.Workflow>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Workflows/{workflowId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{workflowId}", workflowId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Workflow, request, context);
        });
        return request;
    }

    public ListWorkflows(contexts: DataContext[] | DataContext, programId: string,
        optionalParameters: {
            revisionId?: string
        }): Observable<ITypedServiceResponse<Models.Workflow>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Workflows";
        url = url.replace("{programId}", programId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.revisionId != null)
            params = params.set('revisionId', optionalParameters.revisionId);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Workflow, request, context);
        });
        return request;
    }

    public AddWorkflow(body: any, contexts: DataContext[] | DataContext, programId: string, changeId: string): Observable<ITypedServiceResponse<Models.Workflow>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Workflows";
        url = url.replace("{programId}", programId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.post<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Workflow, request, context);
        });
        return request;
    }

    public ModifyWorkflow(body: any, contexts: DataContext[] | DataContext, programId: string, workflowId: string, changeId: string): Observable<ITypedServiceResponse<Models.Workflow>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Workflows/{workflowId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{workflowId}", workflowId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Workflow, request, context);
        });
        return request;
    }

    public DeleteWorkflow(idOfDeletedItem: string, contexts: DataContext[] | DataContext, programId: string, workflowId: string, changeId: string): Observable<Response> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/Workflows/{workflowId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{workflowId}", workflowId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.delete<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public ListPolicyOperations(): Observable<Response> {
        // Build the url
        var url = ProgramsService.baseUrl + "PolicyOperations";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ListWorkflowSets(contexts: DataContext[] | DataContext, programId: string): Observable<ITypedServiceResponse<Models.WorkflowSet>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/WorkflowSets";
        url = url.replace("{programId}", programId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.WorkflowSet, request, context);
        });
        return request;
    }

    public AddWorkflowSet(body: any, contexts: DataContext[] | DataContext, programId: string, changeId: string): Observable<ITypedServiceResponse<Models.WorkflowSet>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/WorkflowSets";
        url = url.replace("{programId}", programId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.post<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.WorkflowSet, request, context);
        });
        return request;
    }

    public ModifyWorkflowset(body: any, contexts: DataContext[] | DataContext, programId: string, changeId: string): Observable<ITypedServiceResponse<Models.Workflow>> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/WorkflowSets";
        url = url.replace("{programId}", programId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Workflow, request, context);
        });
        return request;
    }

    public DeleteWorkflowset(idOfDeletedItem: string, contexts: DataContext[] | DataContext, programId: string, workflowSetId: string, changeId: string): Observable<Response> {
        // Build the url
        var url = ProgramsService.baseUrl + "{programId}/WorkflowSets/{workflowSetId}";
        url = url.replace("{programId}", programId);
        url = url.replace("{workflowSetId}", workflowSetId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('changeId', changeId);

        // Make the request
        let request = this.http.delete<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

}